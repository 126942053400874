import styled from "styled-components";

const Icon = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

export const Svg = styled(Icon)<{
  color?: string;
  styleString?: string;
}>`
  ${({ styleString }) => (styleString ? styleString : "")}
`;

export const DefaultSVGCSS = (color: any, active: any, hover: any) => {
  return `
	${color ? `color: ${color};` : `color: currentColor;`}
	
	&:hover{
		${hover ? `color: ${hover};` : ``}
	}

	&:active{
		${active ? `color: ${active};` : ``}
	}
	
		`;
};
