import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import Carousel from "../components/carousel/Carousel";
import CarouselItem from "../components/carousel/CarouselItem";
import EmbeddedVideo from "../components/videoembed";
import colors from "../helpers/colors";
import { Header, Paragraph } from "../helpers/Typography";
import {
  FeaturesContainer,
  FeaturesDescriptionContainer,
  GalleryContainer,
  MultiDiv,
  UnorderedList,
} from "./styles";
import ScrollingImageGrid from "../components/gallery";

const Features = () => {
  const gallery = useRef<HTMLDivElement>(null);
  const mainBoxes = useRef<HTMLDivElement>(null);
  const galleryTL = useRef<gsap.core.Timeline | null>(null);

  const [photoboxes, setPhotoboxes] = useState<HTMLDivElement[]>([]);

  const [currentImg, setCurrentImg] = useState<any>(null),
    currentImgProps: { x: string | number; y: string | number } = {
      x: "",
      y: "",
    },
    [isZooming, setIsZooming] = useState(false),
    mouse = { x: 0, y: 0 };

  let delayedPlay: gsap.core.Tween | undefined = undefined;

  let column = -1;

  function pauseBoxes(b: HTMLDivElement) {
    var classStr = "pb-col0";
    if (b.className.includes("pb-col1")) classStr = "pb-col1";
    if (b.className.includes("pb-col2")) classStr = "pb-col2";
    for (var i = 0; i < mainBoxes.current!.children.length; i++) {
      var c = mainBoxes.current!.children[i];
      // @ts-ignore
      if (c.className.includes(classStr)) {
        // @ts-ignore
        gsap.to(c.tl, { timeScale: 0, ease: "sine" });
      }
    }
  }

  function playBoxes() {
    for (var i = 0; i < mainBoxes.current!.children.length; i++) {
      // @ts-ignore
      var tl = mainBoxes.current!.children[i].tl;
      tl.play();
      gsap.to(tl, {
        duration: 0.4,
        timeScale: 1,
        ease: "sine.in",
        overwrite: true,
      });
    }
  }

  return (
    <>
      <MultiDiv percentage={100} id="about">
        <div
          style={{
            padding: "24px 24px",
            marginBottom: 20,
            textAlign: "center",
            backgroundColor: colors.secondary,
          }}
        >
          <MultiDiv>
            <Header style={{ marginBottom: 16 }} size={62}>
              WHAT IS XORBI?
            </Header>
            <div
              style={{
                margin: "auto",
                width: "40%",
                height: 2,
                backgroundColor: "black",
              }}
            ></div>
            <Paragraph style={{ marginTop: 16 }} size={28}>
              Visit parallel universes where you can reshape your own real
              cities! Travel solo and claim dominion of your own alternate
              Earth. Or go with the group to a shared mirrorworld and
              collaborate with fellow citizens. Use augmented reality with your
              mobile device to access XORBI Pocket starting November 2022.
            </Paragraph>
          </MultiDiv>
        </div>
      </MultiDiv>
      {/* VIDEO */}
      <MultiDiv percentage={100}>
        <div>
          <EmbeddedVideo src="https://www.youtube.com/embed/3cdHWJVnahg" />
        </div>
      </MultiDiv>
      <FeaturesContainer id="features">
        {/* DESCRIPTION */}
        <FeaturesDescriptionContainer>
          <MultiDiv
            percentage={100}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Header
              style={{ textAlign: "center", marginBottom: 8 }}
              size={36}
              color="white"
            >
              FEATURES
            </Header>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "40%",
                height: 2,
                backgroundColor: colors.secondary,
              }}
            />
            <ul style={{ color: colors.primary }}>
              <li>
                Enjoy the multiverse conveniently cleansed of our shadow selves
                by Edgestar engineers
              </li>
              <li>
                Spray graffiti and redecorate buildings around you and plaster
                them with memes using animated GIFs
              </li>
              <li>Collect spoils left behind by our shadow selves</li>
              <li>
                Show off your creativity in daily missions on landmarks near you
              </li>
              <li>
                Check out the handiwork of fellow touring citizens and add your
                own
              </li>
              <li>
                Share your revised cityscapes back on our Earth via social
                networks
              </li>
              <li>
                Discover the multiverse in tandem with your daily commute and
                track your interdimensional jaunts
              </li>
            </ul>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "40%",
                height: 2,
                backgroundColor: colors.secondary,
              }}
            />
          </MultiDiv>
        </FeaturesDescriptionContainer>
        <GalleryContainer>
          <ScrollingImageGrid
            padding={20}
            images={[
              {
                id: 1,
                url: "./assets/img/01.jpeg",
                alt: "./assets/img/01.jpeg",
              },
              {
                id: 2,
                url: "./assets/img/02.jpeg",
                alt: "./assets/img/02.jpeg",
              },
              {
                id: 3,
                url: "./assets/img/03.jpeg",
                alt: "./assets/img/03.jpeg",
              },
              {
                id: 4,
                url: "./assets/img/01.jpeg",
                alt: "./assets/img/01.jpeg",
              },
              {
                id: 5,
                url: "./assets/img/02.jpeg",
                alt: "./assets/img/02.jpeg",
              },
              {
                id: 6,
                url: "./assets/img/03.jpeg",
                alt: "./assets/img/03.jpeg",
              },
            ]}
            columns={2}
          />
        </GalleryContainer>
      </FeaturesContainer>
    </>
  );
};

export default Features;
