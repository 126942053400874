import React from "react";

const FacebookIcon: React.FC<{ color?: string }> = ({ color = "#47C8D0" }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4667 42V25.1078H15V19.0257H19.4667V13.8309C19.4667 9.74875 22.1051 6 28.1847 6C30.6463 6 32.4665 6.23598 32.4665 6.23598L32.3231 11.9156C32.3231 11.9156 30.4668 11.8975 28.4411 11.8975C26.2487 11.8975 25.8974 12.9078 25.8974 14.5847V19.0257H32.4973L32.2102 25.1078H25.8974V42H19.4667Z"
    />
  </svg>
);

export default FacebookIcon;
