import React from "react";
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";
import ModalComponent from "../modal/Modal";
import CustomForm from "./CustomForm";

const MailchimpFormContainer: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const postURL = `https://gmail.us9.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
  return (
    <ModalComponent closeModal={onClose} withDimmer={true}>
      <MailchimpSubscribe
        url={postURL}
        render={({ subscribe, status, message }) => (
          <CustomForm
            closeForm={onClose}
            status={status}
            message={message}
            onValidated={(formData: EmailFormFields) => subscribe(formData)}
          />
        )}
      />
    </ModalComponent>
  );
};

export default MailchimpFormContainer;
