import styled from "styled-components";
import colors from "./colors";
import media from "./media";

export interface TypographyProps {
  color?: string;
  children?: any;
}

export interface ParagraphProps extends TypographyProps {
  size?: number;
  weight?: number;
}

export const MainHeader = styled.div<ParagraphProps>`
  font-family: "AlphaCentauri";
  color: ${({ color }) => color || colors.neutral[100]};
  font-size: 12vh;
  font-weight: ${({ weight }) => (weight ? weight : 300)};
  margin: 0;

  text-shadow: 10px 10px black;

  // @media ${media.tablet} {
  //   font-size: 64px;
  // }

  @media ${media.laptop.small} {
    text-shadow: none;
  }
`;

export const GlitchTextContainer = styled.div`
  position: relative;
  height: 12vh;

  // @media ${media.tablet} {
  //   height: 64px;
  // }
`;

export const GlitchText = styled.div``;

export const GlitchTextTop = styled(GlitchText)``;

export const GlitchTextBottom = styled(GlitchText)``;

export const Header = styled.div<ParagraphProps>`
  font-family: "LemonMilk";
  color: ${({ color }) => color || colors.neutral[100]};
  font-size: ${({ size }) => (size ? size : 18)}px;
  font-weight: ${({ weight }) => (weight ? weight : 300)};
  margin: 0;

  @media ${media.mobile.large} {
    font-size: ${({ size }) => (size ? size - 10 : 18)}px;
  }
`;

export const Paragraph = styled.div<ParagraphProps>`
  font-family: "Bahnschrift";
  color: ${({ color }) => color || colors.neutral[100]};
  font-size: ${({ size }) => (size ? size : 18)}px;
  font-weight: ${({ weight }) => (weight ? weight : 300)};
  // margin: 0;

  // @media ${media.mobile.large} {
  //   font-size: ${({ size }) => (size ? size - 4 : 18)}px;
  // }
`;

export const Link = styled.div<ParagraphProps>`
  font-family: "Bahnschrift";
  color: ${({ color }) => color || colors.neutral[100]};
  font-size: ${({ size }) => (size ? size : 18)}px;
  font-weight: ${({ weight }) => (weight ? weight : 300)};
  margin: 0;

  @media ${media.mobile.large} {
    font-size: ${({ size }) => (size ? size : 18)}px;
  }
`;

export const Button = styled.div<ParagraphProps>`
  font-family: "Bahnschrift";
  color: ${({ color }) => color || colors.neutral[100]};
  font-size: ${({ size }) => (size ? size : 18)}px;
  font-weight: ${({ weight }) => (weight ? weight : 300)};
  margin: 0;

  @media ${media.mobile.large} {
    font-size: ${({ size }) => (size ? size : 18)}px;
  }
`;

export const InnerText = styled.span<ParagraphProps>`
  font-family: "Bahnschrift";
  ${({ color }) => color && `color: ${color};`}
  ${({ size }) => size && `font-size: ${size}px;`}
  font-weight: ${({ weight }) => (weight ? weight : `inherit`)};
  line-height: initial;
  margin: 0;
`;
