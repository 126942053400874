import styled from "styled-components";
import colors from "../../helpers/colors";
import media from "../../helpers/media";

export const FormContainer = styled.div`
  background-color: black;

  pointer-events: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 28px;
  border-radius: 6px;

  border-style: solid;
  border-color: white;
  border-width: 1px;

  @media ${media.tablet} {
    width: 75%;
  }
`;

export const FormContainerInner = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  text-align: center;
`;

export const SubmitButton = styled.input<{
  disabled?: boolean;
}>`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  border-radius: 6px;

  width: 150px;
  max-width: 366px;
  //   height: 36px;

  padding: 12px;

  margin: auto;

  ${({ disabled }) =>
    disabled
      ? `background-color: #A2AAB4; color: color: rgba(102, 112, 128, 0.4); cursor: not-allowed;`
      : `cursor: pointer; background-color: white; color: black;`}

  &:hover {
    ${({ disabled }) =>
      disabled ? `` : `background-color: ${colors.secondary};`}
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? ``
        : `background-color: #47c8d0; color: rgba(255, 255, 255, 0.4);`}
  }
`;

export const CloseFormButton = styled.button<{
  disabled?: boolean;
}>`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  border-radius: 6px;

  max-width: 366px;
  height: 48px;

  padding: 24px 24px;

  ${({ disabled }) =>
    disabled
      ? `background-color: #A2AAB4; color: color: rgba(102, 112, 128, 0.4);`
      : `cursor: pointer; background-color: #47c8d0; color: black;`}

  &:hover {
    ${({ disabled }) => (disabled ? `` : `background-color: white;`)}
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? ``
        : `background-color: #47c8d0; color: rgba(255, 255, 255, 0.4);`}
  }
`;

export const Label = styled.label``;

export const CloseButton = styled.div<{ z?: number }>`
  color: #e1e1e3;
  z-index: ${({ z }) => z || "1002"};
  svg:hover {
    cursor: pointer;
  }

  position: absolute;
  top: 6px;
  right: 6px;
`;
