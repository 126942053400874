import React, {
  useRef,
  useState,
  useEffect,
  Ref,
  RefObject,
  useLayoutEffect,
} from "react";
import gsap, { Power2 } from "gsap";
import "./styles.css";

const ScrollingImageGrid: React.FC<{
  images: { id: number; url: string; alt: string }[];
  padding?: number;
  columns: number;
}> = ({ images, columns, padding = 10 }) => {
  const gallery = useRef<HTMLDivElement>(null);
  const imgRefs = useRef<(HTMLDivElement | null)[]>([]);
  let fullscreenImage: any | null = null;
  // const [fullscreenImage, setFullscreenImage] = useState<HTMLDivElement | null>(null);
  let isZooming = false;
  const tl = useRef<gsap.core.Timeline | null>(null);

  const currentImgProps: { x: string | number; y: string | number } = {
    x: "",
    y: "",
  };

  // this is to prevent the onmouseenter/leave tweens from fumbling over each other
  let delayedPlay: gsap.core.Tween | undefined = undefined;

  let images_per_col = images.length / columns;

  function playScroll() {
    // tl.current!.play();
    gsap.to(tl.current!, {
      duration: 0.4,
      timeScale: 1,
      ease: "sine.in",
      overwrite: true,
    });
  }

  function stopScroll() {
    // tl.current!.pause();
    gsap.to(tl.current!, { timeScale: 0, ease: "sine" });
  }

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = new gsap.core.Timeline({ paused: false });
      tl.current.set(".grid-item", { opacity: 1, cursor: "pointer" });

      imgRefs.current = imgRefs.current.slice(0, images.length);

      // const galleryTop = gallery.current!.clientTop;
      const galleryHeight = gallery.current!.offsetHeight;
      const galleryWidth = gallery.current!.offsetWidth;

      imgRefs.current.forEach((img, index) => {
        const columnNumber = Math.floor(index / images_per_col);
        const columnIndex = index % images_per_col;
        const percentage = columnIndex / images_per_col;
        const height = galleryHeight / (images_per_col - 1) - padding;
        const width = galleryWidth / columns - padding;

        gsap.set(img, {
          attr: {
            id: "b" + index + "-" + percentage,
            class: "grid-item col" + columnNumber,
          },
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          overflow: "hidden",
          x: `${columnNumber * width + padding * columnNumber}`,
          y:
            columnNumber % 2 === 0
              ? `${-height + height * columnIndex + padding * columnIndex}`
              : `${
                  galleryHeight - height * columnIndex - padding * columnIndex
                }`,
          width: `${width}`,
          height: `${height}`,
          borderRadius: 20,
          // scale: 0.5,
          zIndex: 1,
        });

        tl.current!.to(
          img,
          {
            y:
              columnNumber % 2 === 0
                ? `${
                    galleryHeight + height * columnIndex + padding * columnIndex
                  }`
                : `${-height - height * columnIndex - padding * columnIndex}`,
            ease: "none",
            duration: 30,
            modifiers: {
              y: gsap.utils.unitize(gsap.utils.wrap(-height, galleryHeight)), // force y value to be between 0 and 150%
            },
            repeat: -1,
          },
          0
          // delay
        ); /*.progress();*/

        // individual image callbacks
        // when the mouse enters, stop the scroll and highlight the hovered item
        img!.onmouseenter = function (e) {
          if (fullscreenImage) return;
          if (delayedPlay) delayedPlay.kill();
          stopScroll();
          var _t = e.currentTarget;
          gsap.to(".grid-item", {
            duration: 0.2,
            overwrite: "auto",
            opacity: function (i, t) {
              return t === _t ? 1 : 0.33;
            },
          });
          gsap.fromTo(
            _t,
            { zIndex: 100 },
            { duration: 0.2, scale: 1.5, overwrite: "auto", ease: "power3" }
          );
        };

        // on mouse leave, shrink the highlighted image down and continue the scroll animation
        img!.onmouseleave = function (e) {
          if (fullscreenImage) return;
          var _t = e.currentTarget;

          if (gsap.getProperty(_t, "scale") > 0.62)
            delayedPlay = gsap.delayedCall(0.3, playScroll);
          // to avoid jump, add delay when mouseout occurs as big image scales back down (not 100% reliable because the scale value sometimes evaluates too late)
          else playScroll();

          gsap
            .timeline()
            .set(_t, { zIndex: 1 })
            .to(
              _t,
              { duration: 0.3, scale: 1, overwrite: "auto", ease: "expo" },
              0
            )
            .to(
              ".grid-item",
              { duration: 0.5, opacity: 1, ease: "power2.inOut" },
              0
            );
        };

        // on click, if the image is already selected, shrink it down to where it was and
        // continue the scroll animation
        // if it is not selected then grow the image up and hide the others
        img!.onclick = function (e) {
          if (!isZooming) {
            //only tween if photoBox isn't currently zooming

            isZooming = true;
            // our animation takes ~.8s so delay it
            gsap.delayedCall(0.8, function () {
              isZooming = false;
            });

            if (fullscreenImage) {
              gsap
                .timeline({ defaults: { ease: "expo.inOut" } })
                // .to(
                //   ".mainClose",
                //   { duration: 0.1, autoAlpha: 0, overwrite: true },
                //   0
                // )
                // .to(
                //   ".mainBoxes",
                //   {
                //     duration: 0.5,
                //     scale: 1,
                //     // left: "75%",
                //     // width: 1200,
                //     // rotationX: 14,
                //     // rotationY: -15,
                //     // rotationZ: 10,
                //     overwrite: true,
                //   },
                //   0
                // )
                .to(
                  ".grid-item",
                  { duration: 0.6, opacity: 1, ease: "power4.inOut" },
                  0
                )
                .to(
                  fullscreenImage,
                  {
                    duration: 0.6,
                    width: `${width}`,
                    height: `${height}`,
                    borderRadius: 20,
                    x: `${columnNumber * width + padding * columnNumber}`,
                    y: currentImgProps.y,
                    // scale: 0.5,
                    rotation: 0,
                    zIndex: 1,
                  },
                  0
                )
                .to(fullscreenImage, {
                  duration: 0,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                })
                .add(playScroll, 0.8);
              // .add(playBoxes, 0.8)
              fullscreenImage = null;
            } else {
              // pauseBoxes(e.currentTarget);
              stopScroll();
              fullscreenImage = e.currentTarget;

              currentImgProps.x = gsap.getProperty(fullscreenImage, "x");
              currentImgProps.y = gsap.getProperty(fullscreenImage, "y");

              gsap
                .timeline({ defaults: { duration: 0.6, ease: "expo.inOut" } })
                .set(fullscreenImage, { zIndex: 100 })
                // .fromTo(
                //   ".mainClose",
                //   { x: mouse.x, y: mouse.y, background: "rgba(0,0,0,0)" },
                //   { autoAlpha: 1, duration: 0.3, ease: "power3.inOut" },
                //   0
                // )
                .to(".grid-item", { opacity: 0 }, 0)
                .to(
                  fullscreenImage,
                  {
                    width: "100%",
                    height: "100%",
                    borderRadius: 0,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    x: 0,
                    top: 0,
                    y: 0,
                    scale: 1,
                    opacity: 1,
                  },
                  0
                );
              // .to(
              //   ".mainBoxes",
              //   {
              //     duration: 0.5,
              //     left: "50%",
              //     width: "100%",
              //     rotationX: 0,
              //     rotationY: 0,
              //     rotationZ: 0,
              //   },
              //   0.15
              // )
              // .to(
              //   ".mainBoxes",
              //   { duration: 5, scale: 1.06, rotation: 0.05, ease: "none" },
              //   0.65
              // );
            }
          }
        };
      });

      playScroll();
    }, gallery);

    return () => ctx.revert();
  }, []);

  // const columnArrays: { id: number; url: string; alt: string }[][] = [];
  // for (let i = 0; i < columns; i++) {
  //   columnArrays.push([]);
  // }

  // images.forEach((image, index) => {
  //   const columnIndex = Math.floor(index / images_per_col);
  //   columnArrays[columnIndex].push(image);
  // });

  return (
    <div ref={gallery} className="grid-container">
      {images.map((img, index) => (
        <div
          ref={(el) => (imgRefs.current[index] = el)}
          key={img.id}
          className="grid-item"
          style={{
            cursor: "poitner",
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
            backgroundImage: `url(${img.url})`,
          }}
        />
      ))}
    </div>
  );
};

export default ScrollingImageGrid;
