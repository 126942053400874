import React, { Ref } from "react";
import { MouseEventHandler } from "react";
import styled from "styled-components";
import Dimmer from "./Dimmer";

const Modal = styled.div<{
  zIndex?: number;
}>`
  position: fixed; /* Stay in place */
  z-index: ${({ zIndex }) => zIndex || 1001}; /* Sit on top of dimmer */
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const ModalComponent = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    withDimmer: boolean;
    closeModal: () => void;
  }
>(({ children, withDimmer, closeModal }, ref: Ref<HTMLDivElement>) => {
  return (
    <>
      {withDimmer && <Dimmer isOpen={true} onClick={closeModal} />}
      <Modal ref={ref}>{children}</Modal>
    </>
  );
});

export default ModalComponent;
