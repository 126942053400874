import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DownwardArrowIcon from "../components/icons/DownwardArrow";
import { ClickableSVGContainer } from "../components/styles";
import colors from "../helpers/colors";
import {
  GlitchTextBottom,
  GlitchTextContainer,
  GlitchTextTop,
  Header,
  MainHeader,
  Paragraph,
} from "../helpers/Typography";
import GooglePlay from "./res/GooglePlayBlack.png";
import AppStore from "./res/AppStoreBlack.png";
import {
  CenteredButtonRow,
  CircleContainer,
  HeroButtonContainer,
  HeroContainer,
  HeroContentContainer,
  HeroFooter,
  ImgContainer,
  MapComponent,
} from "./styles";
import gsap from "gsap";
import MapIcon from "../components/icons/Map";

// @ts-ignore
function Box({ children }) {
  return <div className="box">{children}</div>;
}

// @ts-ignore
function Circle({ radius = 70, children = undefined }) {
  return (
    <CircleContainer className="circle exit" radius={radius}>
      {children}
    </CircleContainer>
  );
}

function PointXOnCircle(radius: number, dev: number) {
  const angle = Math.random() * Math.PI * 2;
  const x = Math.cos(angle) * radius + (Math.random() - 0.5) * dev;

  return x;
}

function PointYOnCircle(radius: number, dev: number) {
  const angle = Math.random() * Math.PI * 2;
  const y = Math.sin(angle) * radius + (Math.random() - 0.5) * dev;

  return y;
}

const Hero = () => {
  // really bad but hey...
  // https://bobbyhadz.com/blog/react-scroll-to-element-on-click
  let download: HTMLElement | null;
  const hero = useRef<HTMLDivElement>(null);
  const [reversed, setReversed] = useState(false);

  // in order to avoid creating a new timeline on every render, it's important to create the timeline inside an effect and store it in a ref.
  const tl = useRef<gsap.core.Timeline | null>(null);
  const entrance_tl = useRef<gsap.core.Timeline | null>(null);
  const disappear_tl = useRef<gsap.core.Timeline | null>(null);
  const idle_tl = useRef<gsap.core.Timeline | null>(null);
  const map_tl = useRef<gsap.core.Timeline | null>(null);
  const glitch_tl = useRef<gsap.core.Timeline | null>(null);

  function entrance() {
    entrance_tl.current = gsap.timeline();

    entrance_tl.current.fromTo(
      ".circle",
      {
        scale: () => Math.random(),
        opacity: 0,
        y: () => PointYOnCircle(400, 10),
        x: () => PointXOnCircle(400, 10),
        stagger: 0.25,
      },
      {
        scale: 1,
        duration: 2,
        x: 0,
        y: 0,
        opacity: (index, target) => {
          // the planet is index 3, we want the opacity to band out
          // from there
          if (index === 3) return 0.2;
          const offset = Math.abs(index - 3) / 20;
          // console.log(offset)
          return 0.2 - offset;
        },
      },
      "+=1"
    );

    return entrance_tl.current;
  }

  function glitch() {
    glitch_tl.current = gsap.timeline({ repeat: -1, repeatDelay: 20 });

    glitch_tl.current
      .to(".glitch", { duration: 0.1, skewX: 70, ease: "power4.inout" })
      .to(".glitch", { duration: 0.04, skewX: 0, ease: "power4.inout" })
      .to(".glitch", { duration: 0.04, opacity: 0 })
      .to(".glitch", { duration: 0.04, opacity: 1 })
      .to(".glitch", { duration: 0.04, x: -20 })
      .to(".glitch", { duration: 0.04, x: 0 });

    return glitch_tl.current;
  }

  function mapFadeIn() {
    map_tl.current = gsap.timeline();

    map_tl.current.fromTo(
      ".map",
      {
        duration: 3,
        opacity: 0,
      },
      {
        opacity: 0.1,
      },
      "+=1"
    );

    return map_tl.current;
  }

  function disappear() {
    disappear_tl.current = gsap.timeline();
    disappear_tl.current.to(
      ".exit",
      {
        duration: 1,
        opacity: 0,
        scale: () => Math.random(),
        y: () => PointYOnCircle(400, 10),
        x: () => PointXOnCircle(400, 10),
        stagger: 0.25,
      },
      "+=0.5"
    );
    return disappear_tl.current;
  }

  function idle() {
    idle_tl.current = gsap.timeline({ repeat: -1 });
    idle_tl.current
      .to(".planet", {
        duration: 5,
        y: "-=30",
        x: "+=20",
        ease: "power1.inOut",
      })
      .to(".planet", {
        duration: 2,
        y: "+=30",
        x: "-=20",
        ease: "power1.inOut",
      })
      .to(".planet", {
        duration: 3,
        y: "-=20",
        ease: "power1.inOut",
      })
      .to(".planet", {
        duration: 3,
        y: "+=20",
        ease: "power1.inOut",
      });

    return idle_tl.current;
  }

  const handleClick = () => {
    if (download) {
      download.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // 👇️ use document.getElementById()
    download = document.getElementById("about");
  }, []);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // use scoped selectors

      tl.current = gsap
        .timeline()
        .add(entrance())
        .add(mapFadeIn())
        .add(glitch(), "+=2")
        .add(disappear(), "<")
        .add(idle());
    }, hero);

    return () => ctx.revert();
  }, []);

  // useEffect(() => {
  //   // toggle the direction of our timeline
  //   console.log("toggling reverse to", reversed);
  //   entrance_tl.current!.reversed(reversed);
  // }, [reversed]);

  return (
    <HeroContainer ref={hero}>
      <div>
        <HeroContentContainer>
          {/* CIRCLES */}
          <div>
            <Circle radius={55} />
            <Circle radius={60} />
            <Circle radius={65} />
            <CircleContainer
              className="circle planet"
              radius={70}
            ></CircleContainer>
            <Circle radius={75} />
            <Circle radius={80} />
            <Circle radius={85} />
          </div>
          <MapComponent className="map">
            <MapIcon />
          </MapComponent>
          {/* GLITCH */}
          <GlitchTextContainer>
            <GlitchTextTop className="glitch top">
              <MainHeader
                style={{ letterSpacing: 20, marginBottom: 40 }}
                size={64}
                color="white"
              >
                XORBI
              </MainHeader>
            </GlitchTextTop>
            <GlitchTextBottom className="glitch bottom">
              <MainHeader
                style={{ letterSpacing: 20, marginBottom: 40 }}
                size={64}
                color="white"
              >
                XORBI
              </MainHeader>
            </GlitchTextBottom>
          </GlitchTextContainer>

          <Header size={24} color={colors.secondary}>
            ALT EARTH TOURS
          </Header>
          <Paragraph color="rgba(150, 150, 150)" weight={200} size={14}>
            <i style={{ background: "black" }}>Brought to you by Edgestar</i>
          </Paragraph>
        </HeroContentContainer>
      </div>

      <HeroFooter>
        <Header
          color="white"
          style={{
            display: "inline-block",
            background: "black",
            padding: 10,
            borderRadius: 6,
          }}
          size={36}
        >
          DOWNLOAD HERE
        </Header>
        <Paragraph
          color="white"
          size={20}
          style={{
            display: "inline-block",
            background: "black",
            padding: 10,
            borderRadius: 6,
          }}
        >
          Our Alpha ver 1.0 is out. Get it on your mobile phone today!
        </Paragraph>
        <CenteredButtonRow
          style={{ width: "80vw", justifyContent: "space-evenly", zIndex: 100 }}
        >
          <a href="https://play.google.com/store/apps/details?id=com.GIGO.xorbi">
            <ImgContainer src={GooglePlay} />
          </a>
          <a href="https://apps.apple.com/us/app/xorbi-pocket/id6443657755">
            <ImgContainer src={AppStore} />
          </a>
        </CenteredButtonRow>
        <HeroButtonContainer>
          <ClickableSVGContainer
            onClick={handleClick}
            color={"white"}
            hover={colors.secondary}
            active={"white"}
          >
            <DownwardArrowIcon />
          </ClickableSVGContainer>
        </HeroButtonContainer>
      </HeroFooter>
    </HeroContainer>
  );
};

export default Hero;
