import styled from "styled-components";

export const ClickableSVGContainer = styled.a<{
  color: string;
  hover: string;
  active: string;
}>`
  &:hover {
    /* GRAY 40% */

    background: rgba(102, 112, 128, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	background-color: transparent;

    & svg {
		fill: ${({ hover }) => hover};
    }
  }

  &:active {
    /* GRAY 40% */

    background: rgba(102, 112, 128, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	background-color: transparent;

    & svg {
		fill: ${({ active }) => active};
    }
  }

  & svg {
    display: block;
    margin: auto;

    // not sure why this works
    // margin-top: 25%;
    fill: ${({ color }) => color};
  }
`;
