import React, { useEffect, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Header, Paragraph } from "../../helpers/Typography";
import CloseIcon from "../icons/Close";
import InputField from "./InputField";
import {
  CloseButton,
  CloseFormButton,
  FormContainer,
  FormContainerInner,
  SubmitButton,
} from "./styles";

const CustomForm: React.FC<{
  status: string | null;
  message: string | Error | null;
  closeForm: () => void;
  onValidated: (arg0: {
    EMAIL: string;
    MERGE1: string;
    MERGE2: string;
    MERGE3: string;
    MERGE4: string;
  }) => void;
}> = ({ status, message, onValidated, closeForm }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // stop page from refreshing
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      country &&
      postalCode &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
        MERGE3: country,
        MERGE4: postalCode,
      });
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setCountry("");
    setPostalCode("");
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  return (
    <FormContainer>
      <CloseButton>
        <div onClick={closeForm}>
          <CloseIcon />
        </div>
      </CloseButton>
      <FormContainerInner>
        <Header color="white" style={{ marginBottom: 16 }}>
          {status === "success"
            ? "Success!"
            : "Join our email list for future updates."}
        </Header>

        {status === "sending" && (
          <Paragraph color="white" style={{ marginBottom: 16 }}>
            sending...
          </Paragraph>
        )}
        {status === "error" && (
          // <div dangerouslySetInnerHTML={{ __html: message as string }} />
          <Paragraph style={{ marginBottom: 16 }} color="red">
            {message}
          </Paragraph>
        )}

        {/* if status isn't success, show the form */}
        {status !== "success" && (
          <form onSubmit={(e) => handleSubmit(e)}>
            {/* {status === "success" && (
              // <div dangerouslySetInnerHTML={{ __html: message as string }} />
              <Paragraph style={{ marginBottom: 16 }} color="green">
                {message}
              </Paragraph>
            )} */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 12,
                marginBottom: 16,
              }}
            >
              <InputField
                label="First Name"
                onChangeHandler={setFirstName}
                type="text"
                value={firstName}
                placeholder="Jane"
                isRequired
                name={""}
                formValues={undefined}
              />

              <InputField
                label="Last Name"
                onChangeHandler={setLastName}
                type="text"
                value={lastName}
                placeholder="Doe"
                isRequired
                name={""}
                formValues={undefined}
              />

              <InputField
                label="Email"
                onChangeHandler={setEmail}
                type="email"
                value={email}
                placeholder="your@email.com"
                isRequired
                name={""}
                formValues={undefined}
              />

              <InputField
                label="Country"
                onChangeHandler={setCountry}
                type="dropdown"
                value={country}
                placeholder="Country"
                isRequired
                name={""}
                formValues={undefined}
              />

              <InputField
                label="Postal Code"
                onChangeHandler={setPostalCode}
                type="text"
                value={postalCode}
                placeholder="####"
                isRequired
                name={""}
                formValues={undefined}
              />
            </div>

            <InputField
              label="subscribe"
              type="submit"
              formValues={[email, firstName, lastName, country, postalCode]}
              name={""}
              value={undefined}
              placeholder={undefined}
              onChangeHandler={function (arg0: any): void {
                throw new Error("Function not implemented.");
              }}
            />
          </form>
        )}

        {status === "success" && (
          <>
            <Paragraph color="white" style={{ marginBottom: 16 }} size={20}>
              Thank you for your interest!
            </Paragraph>
            <CloseFormButton onClick={closeForm}>Close</CloseFormButton>
          </>
        )}
      </FormContainerInner>
    </FormContainer>
  );
};

export default CustomForm;
