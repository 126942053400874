import styled, { keyframes } from "styled-components";
import colors from "../helpers/colors";
import media from "../helpers/media";

import HeroBG from "./res/HeroBG.png";
import Map from "./res/diy_map.svg";
import World from "./res/world.svg";
import WorldMapBlue from "./res/World_map_blue.svg";
import Globe from "./res/globe.svg";
import GlobeV2 from "./res/globe_v2.svg";
import WorldMap from "./res/world_map.svg";

// Create the keyframes
const bounce = keyframes`
0%, 100% {
  transform: translateY(0);
}
50% {
  transform: translateY(-10px);
}
`;

export const MultiDiv = styled.div<{
  percentage?: number;
}>`
  margin: auto;
  width: ${({ percentage }) => (percentage ? percentage : `50`)}%;

  @media ${media.tablet} {
    width: 100%;
  }
`;

export const ImgContainer = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

export const PageContainer = styled.div`
  width: 100%;

  // background: linear-gradient(
  //   180deg,
  //   #010a2c 2.6%,
  //   #22224f 52.34%,
  //   #20095b 64.32%,
  //   #1f1f4c 77.34%,
  //   #020227 100%
  // );
  background: black;
`;

export const HeroContainer = styled.div`
  min-height: 100vh;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${media.mobile.large} {
    // background: url(${Map}) no-repeat center center;
    // background-size: cover;
    // to fix the view height mobile issue
    min-height: calc(var(--vh, 1vh) * 100);
  }

  // to protect against landscape view
  @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    // min-height: 80vw;
  }

  border: #fbab40;
  background: black;
`;

export const HeroContentContainer = styled.div`
  position: relative;
  color: white;
  display: inline-block;

  text-align: center;
  width: 100%;

  margin-top: 30vh;
  // left: 50%;
  // transform: translate(-50%, -50%);

  @media ${media.laptop.small} {
    margin-top: 20vh;
    // top: 20%;
  }
`;

export const HeroButtonContainer = styled.div`
  // position: absolute;
  // transform: translate(-50%, -50%);

  z-index: 100;

  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 60px;
  margin: auto;

  cursor: pointer;
  width: 50px;
  height: 50px;

  animation: ${bounce} 2s linear infinite;

  &:hover {
    animation: none;
  }

  @media ${media.mobile.large} {
    // margin-top: 60px;
    width: 35px;
    height: 35px;
    // bottom: 35px;
  }
`;

export const HeroFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  // position: absolute;
  margin-bottom: 5vh;
  // left: 0;
  // right: 0;

  @media ${media.laptop.small} {
    // bottom: 100px;
  }
`;

export const CircleContainer = styled.div<{ radius?: number }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  width: ${({ radius }) => (radius ? radius : 70)}vh;
  height: ${({ radius }) => (radius ? radius : 70)}vh;

  /* transform: translate(50%, 50%); */
  border-radius: 50%;
  border: 5px solid white;
  display: inline-block;
  /* background-color: green; */
  /* color: white; */

  @media screen and ${media.laptop.small} {
    // top: -35%;
    width: ${({ radius }) => (radius ? radius - 25 : 70)}vw;
    height: ${({ radius }) => (radius ? radius - 25 : 70)}vw;
  }

  @media ${media.mobile.large} {
    // top: -50%;
  }
`;

export const MapComponent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -10%;
  bottom: 0;
  margin: auto;

  width: 70vw;
  height: 60vh;

  max-width: 1000px;

  color: ${colors.primary};

  // background: url(${Map}) no-repeat center center;
  // background-size: contain;
  // background-repeat: no-repeat;

  @media ${media.laptop.small} {
    // top: -35%;
  }

  @media ${media.mobile.large} {
    width: 90vw;
    height: 90vw;
    // top: -50%;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  padding: 24px 24px;
`;

export const DownloadContainer = styled.div`
  background-color: ${colors.secondary};
  padding: 24px 24px;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 24px 24px;

  height: 75vh;
  overflow: hidden;

  // & div {
  //   width: 50%;
  // }

  @media ${media.laptop.small} {
    display: block;
    height: auto;

    & div {
      width: auto;
    }
  }
`;

export const FeaturesDescriptionContainer = styled.div`
  @media ${media.laptop.small} {
    margin-bottom: 24px;
  }
`;

export const GalleryContainer = styled.div`
  width: 100%;
  @media ${media.laptop.small} {
    display: block;
    height: 75vh;
  }
`;

export const MailingListButton = styled.button<{
  disabled?: boolean;
}>`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 24px;
  border-radius: 6px;

  max-width: 366px;
  height: 48px;

  padding: 24px 24px;

  ${({ disabled }) =>
    disabled
      ? `background-color: #A2AAB4; color: color: rgba(102, 112, 128, 0.4);`
      : `cursor: pointer; background-color: white; color: black;`}

  &:hover {
    ${({ disabled }) =>
      disabled ? `` : `background-color: ${colors.secondary};`}
  }

  &:active {
    ${({ disabled }) =>
      disabled
        ? ``
        : `background-color: #47c8d0; color: rgba(255, 255, 255, 0.4);`}
  }
`;

export const CenteredButtonRow = styled.div<{
  gap?: number;
}>`
  display: flex;
  justify-content: center;
  gap: ${({ gap }) => (gap === undefined ? `8px` : `${gap}px`)};
`;

// for some reason, styled components very displeased w/ this
export const UnorderedList = styled.ul`
  list-style: none;

  & li::before {
    // unicode for bullet
    content: "\2022";

    color: ${colors.secondary};
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
`;

// export const ULListItem = styled.li`
//   &:before {
//     content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
//     color: ${colors.secondary}; /* Change the color */
//     font-weight: bold; /* If you want it to be bold */
//     display: inline-block; /* Needed to add space between the bullet and the text */
//     width: 1em; /* Also needed for space (tweak if needed) */
//     margin-left: -1em; /* Also needed for space (tweak if needed) */
//   }
// `;
