const colors = {
  primary: "#ebd2c7",
  secondary: "#D8F0B3",
  neutral: {
    0: "#ffffff",
    5: "#f0f0f1",
    10: "#e1e1e3",
    20: "#d2d2d6",
    30: "#b4b4bb",
    40: "#9696a0",
    50: "#787885",
    60: "#5a5b6a",
    70: "#4a4b57",
    80: "#3a3a44",
    90: "#292a31",
    100: "#19191d",
  },
};

export default colors;
