// https://dev.to/alaskaa/how-to-import-a-web-font-into-your-react-app-with-styled-components-4-1dni

import { createGlobalStyle } from "styled-components";

import AlphaCentauri from "./Alphacentauri-MVY4p.ttf";
import LemonMilk from "./LEMONMILK-Regular.otf";
import Bahnschrift from "./Bahnschrift_Regular.ttf";

export default createGlobalStyle`
	@font-face {
		font-family: 'AlphaCentauri';
		src: local('AlphaCentauri'),
			 url(${AlphaCentauri}) format('truetype');
		font-weight: 300;
        font-style: normal;
	}
	
	@font-face {
		font-family: 'Bahnschrift';
		src: local('Bahnschrift'),
			 url(${Bahnschrift}) format('truetype');
		font-weight: 300;
		font-style: normal;
	}
	
	@font-face {
		font-family: 'LemonMilk';
		src: local('LemonMilk'),
			 url(${LemonMilk}) format('opentype');
		font-weight: 300;
		font-style: normal;
	}
	
`;
