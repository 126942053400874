/* eslint-disable */
//https://jsramblings.com/how-to-use-media-queries-with-styled-components/
/*
	Usage: 
		@media ${media.laptop.small} {
    	.
		.
		.
  	}
*/
const mediaQueries = {
	mobile: {
	  small: '(max-width: 320px)',
	  medium: '(max-width: 375px)',
	  large: '(max-width: 425px)',
	},
	tablet: '(max-width: 768px)',
	laptop: {
	  small: '(max-width: 1024px)',
	  medium: '(max-width: 1440px)',
	  large: '(max-width: 2560px)',
	},
  }
  
  export default mediaQueries
