import React from "react";
import { CenteredButtonRow, DownloadContainer, ImgContainer, MultiDiv } from "./styles";

import GooglePlay from "./res/GooglePlayBlack.png";
import AppStore from "./res/AppStoreBlack.png";
import styled from "styled-components";
import { Header, Paragraph } from "../helpers/Typography";

const Download = () => {
  return (
    <DownloadContainer id="Download">
      <MultiDiv>
        <div style={{ textAlign: "center", marginBottom: 20 }}>
          <Header style={{ marginBottom: 8 }} size={36}>
            DOWNLOAD HERE
          </Header>
          <Paragraph size={20}>
            Our Alpha ver 1.0 is out. Get it on your mobile phone today!
          </Paragraph>
        </div>
        <CenteredButtonRow>
          <a href="https://play.google.com/store/apps/details?id=com.GIGO.xorbi">
            <ImgContainer src={GooglePlay} />
          </a>
          <a href="https://apps.apple.com/us/app/xorbi-pocket/id6443657755">
            <ImgContainer src={AppStore} />
          </a>
        </CenteredButtonRow>
      </MultiDiv>
    </DownloadContainer>
  );
};

export default Download;
