import React from "react";
// import logo from "./logo.svg";
import "./App.css";
import Page from "./pages";

import GlobalFonts from './fonts/fonts';
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Wrapper = styled.div``

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Wrapper>
      <Helmet>
        <title>Xorbi</title>
      </Helmet>
      <GlobalFonts />
      <Page />
    </Wrapper>
  );
}

export default App;
