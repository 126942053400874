import { MouseEventHandler } from 'react'
import styled from 'styled-components'

const Dimmer = styled.div<{
  isOpen: boolean
  onClick: MouseEventHandler<HTMLElement> | undefined
  zIndex?: number
}>`
  display: ${({ isOpen }) =>
    isOpen ? 'block' : 'none'}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: ${({ zIndex }) => zIndex || 1000}; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
`

export default Dimmer
