import React from "react";
import DiscordIcon from "../components/icons/Discord";
import FacebookIcon from "../components/icons/Facebook";
import InstagramIcon from "../components/icons/Instagram";
import TwitterIcon from "../components/icons/Twitter";
import { ClickableSVGContainer } from "../components/styles";
import colors from "../helpers/colors";
import { Header, Paragraph } from "../helpers/Typography";
import {
  CenteredButtonRow,
  ContactContainer,
  MailingListButton,
} from "./styles";

const Contact: React.FC<{
  formCallback: (arg1: boolean) => void;
}> = ({ formCallback }) => {
  return (
    <ContactContainer>
      <Header
        size={34}
        style={{ textAlign: "center", marginBottom: 10 }}
        color="white"
      >
        STAY UPDATED BY JOINING OUR MAILING LIST
      </Header>
      <MailingListButton onClick={() => formCallback(true)}>
        <Paragraph size={20}>Join Mailing List</Paragraph>
      </MailingListButton>
      <div style={{ height: 40 }}></div>
      <Header size={20} color="white">
        CHECK OUT OUR COMMUNITY
      </Header>
      <CenteredButtonRow gap={16}>
        <ClickableSVGContainer
          href={"https://www.facebook.com/XORBI.earth/"}
          color={"white"}
          hover={colors.secondary}
          active={"white"}
        >
          <FacebookIcon />
        </ClickableSVGContainer>
        <ClickableSVGContainer
          href={"https://discord.gg/WvXfyuSY3A"}
          color={"white"}
          hover={colors.secondary}
          active={"white"}
        >
          <DiscordIcon />
        </ClickableSVGContainer>
        <ClickableSVGContainer
          href={"https://twitter.com/XORBI_earth/"}
          color={"white"}
          hover={colors.secondary}
          active={"white"}
        >
          <TwitterIcon />
        </ClickableSVGContainer>
        <ClickableSVGContainer
          href={"https://www.instagram.com/xorbi.earth/"}
          color={"white"}
          hover={colors.secondary}
          active={"white"}
        >
          <InstagramIcon />
        </ClickableSVGContainer>
      </CenteredButtonRow>
      <div style={{ height: 40 }}></div>
      <Paragraph size={20} color="white">
        Contact us!
      </Paragraph>

      <Paragraph size={20} color={colors.secondary}>
        hello@xorbi.earth
      </Paragraph>
    </ContactContainer>
  );
};

export default Contact;
