import React from "react";

const TwitterIcon: React.FC<{ color?: string }> = ({ color = "#47C8D0" }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.286 9.87397C45.616 10.614 43.822 11.114 41.936 11.34C43.882 10.1756 45.3379 8.3429 46.032 6.18397C44.2037 7.26995 42.2027 8.03436 40.116 8.44397C38.7128 6.9457 36.8541 5.95262 34.8287 5.61892C32.8032 5.28521 30.7242 5.62955 28.9145 6.59848C27.1048 7.5674 25.6656 9.10669 24.8203 10.9774C23.9751 12.8481 23.7711 14.9455 24.24 16.944C20.5354 16.758 16.9113 15.7951 13.6029 14.1178C10.2945 12.4405 7.37571 10.0863 5.03602 7.20797C4.23602 8.58797 3.77602 10.188 3.77602 11.892C3.77513 13.426 4.15289 14.9365 4.87578 16.2894C5.59867 17.6424 6.64435 18.796 7.92002 19.648C6.44058 19.6009 4.99377 19.2011 3.70002 18.482V18.602C3.69987 20.7535 4.44409 22.8387 5.80639 24.504C7.16869 26.1692 9.06517 27.3118 11.174 27.738C9.80159 28.1094 8.3627 28.1641 6.96602 27.898C7.56102 29.7492 8.72001 31.368 10.2808 32.5278C11.8415 33.6876 13.7259 34.3303 15.67 34.366C12.3697 36.9568 8.2938 38.3621 4.09802 38.356C3.35479 38.3562 2.61218 38.3128 1.87402 38.226C6.13298 40.9643 11.0907 42.4176 16.154 42.412C33.294 42.412 42.664 28.216 42.664 15.904C42.664 15.504 42.654 15.1 42.636 14.7C44.4586 13.3819 46.0318 11.7498 47.282 9.87997L47.286 9.87397Z"
    />
  </svg>
);

export default TwitterIcon;
