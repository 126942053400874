import React from 'react'
import { VideoContainer } from './styles'

const EmbeddedVideo: React.FC<{
	src: string
}> = ({ src }) => (
  <VideoContainer>
    <iframe
      width="853"
      height="480"
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </VideoContainer>
)

export default EmbeddedVideo
