import React from "react";
import colors from "../../helpers/colors";
import { Paragraph } from "../../helpers/Typography";

const Footer: React.FC<{}> = () => {
  return (
    <div style={{ textAlign: "center", padding: "32px 24px" }}>
      <Paragraph weight={200} size={14} color={"white"}>
        © 2022 EX ORBI LTD
      </Paragraph>
      <br />
      <Paragraph weight={200} size={14} color={"white"}>
        Google Play and the Google Play logo are trademarks of Google LLC
      </Paragraph>
      <br />
      <Paragraph weight={200} size={14} color={"white"}>
        Apple and Apple logo are trademarks of Apple Inc., registered in the
        U.S. and other countries.
      </Paragraph>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 16,
          justifyContent: "center",
          marginTop: 32,
        }}
      >
        <a href="https://termify.io/privacy-policy/1663065021">
          <Paragraph weight={200} size={14} color={colors.secondary}>
            Privacy Policy
          </Paragraph>
        </a>
        <a href="https://termify.io/terms-and-conditions/1663143110">
          <Paragraph weight={200} size={14} color={colors.secondary}>
            Terms & Conditions
          </Paragraph>
        </a>
      </div>
    </div>
  );
};

export default Footer;
