import React, { useState } from "react";
import MailchimpFormContainer from "../components/forms/Mailchimp";
import Footer from "../components/nav/Footer";
import Header from "../components/nav/Header";
import LockBody from "../helpers/LockBody";
import Contact from "./Contact";
import Download from "./Download";
import Features from "./Features";
import Hero from "./Hero";
import { PageContainer } from "./styles";

const Page: React.FC<{}> = () => {
  const [displayForm, setDisplayForm] = useState(false);
  return (
    <>
      {displayForm && (
        <>
          <LockBody />
          <MailchimpFormContainer onClose={() => setDisplayForm(false)} />
        </>
      )}
      <PageContainer>
        {/* No header for now, there isn't much purpose right now */}
        {/* {!displayForm && <Header />} */}

        <Hero />
        <Features />
        <Download />
        <Contact formCallback={setDisplayForm} />
        <Footer />
      </PageContainer>
    </>
  );
};

export default Page;
